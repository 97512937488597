/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// CSS
import 'styles/Blog.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { BannerCustom } from 'components/Elements'
import Content from 'components/Content'
import CustomLink from 'components/CustomLink'

// Third Party
import parse from 'html-react-parser'

const Actie = () => {
  const {
    blog: {
      acf: {
        page_content: {
          banner: blogBanner,
          image: {
            localFile: {
              childImageSharp: {
                fluid: blogImage
              }
            }
          }
        }
      },
      yoast_meta: yoast
    },
    blogs: {
      edges: blogList
    }
  } = useStaticQuery(graphql`{
    blog: wordpressPage(wordpress_id: {eq: 559}) {
      acf {
        page_content {
          banner {
            button_text
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    },
    blogs: allWordpressWpActie(sort: {fields: date, order: DESC}) {
      edges {
        node {
          acf {
            blog_content {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              small_introduction
            }
          }
          title
          path
          slug
        }
      }
    }
  }`)

  const chunk = (array, chunkSize) => {
    const R = []
    for (let i = 0; i < array.length; i += chunkSize) {
      R.push(array.slice(i, i + chunkSize));
    }
    return R;
  }

  const [chunkSize, setChunkSize] = useState(6)

  const updateChunk = () => {
    setChunkSize(chunkSize + 6)
  }

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="blog">
        <BackgroundImage className="smaller-background-image blog-background-image" fluid={blogImage} />
        <BannerCustom to="/contact" className="color-background-main" bannerText={blogBanner.text} bannerButtonText={blogBanner.button_text} />
        <div className="container">
          <div className="row py-5">
            {chunk(blogList, chunkSize)[0].map((blog, index) => (
              <ActieItem key={index} className="col-12 col-md-6 col-lg-4" blog={blog.node} />
            ))}
          </div>
          <div className="d-flex justify-content-center pb-5">
            {chunk(blogList, chunkSize).length > 1 && (
              <div tabIndex="0" role="button" className="color-background-contrast font-size-xm font-weight-xl cursor-pointer color-text-light px-4 py-2 text-uppercase" onKeyDown={() => updateChunk()} onClick={() => updateChunk()}>Laad meer</div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Actie

const ActieItem = ({ blog, className }) => (
  <div className={`blog-item ${className ? `${className}` : ``}`}>
    <CustomLink className="blog-item-container" to={`/acties/${blog.slug}`}>
      <div className="blog-item-image-container">
        <Img className="blog-item-image" fluid={blog.acf.blog_content.image.localFile.childImageSharp.fluid} />
      </div>
      <div className="blog-item-content color-background-secondary p-3">
        <h2 className="font-size-m font-weight-xl color-text-main">{parse(blog.title)}</h2>
        <Content className="font-size-sm font-weight-m color-text-main" content={blog.acf.blog_content.small_introduction} />
      </div>
      <p className="blog-item-button font-weight-xl font-size-xm t-minus-20 position-relative float-right px-3 py-2 color-background-contrast">Lees meer</p>
    </CustomLink>
  </div>
)